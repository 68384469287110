import $ from "jQuery";
import { accordionCommon, accordionGMenu, accordionJobInt, accordionProdCdMap, accordionSelectFormRow, get_mrc_d_byte, linkSelect, resizeTransitionControll, searchBoxSubmit, specIframeResponsive, SpMenuObject, tabCommon, tableHint, toggleBtnFixed, toggleHeader, togglePagetop, unitChange } from "./modules/common";
import { initMvSlide } from "./modules/mvSlide";
import { richMenu } from "./modules/richMenu";

$(() => {
  initMvSlide();
  
  new SpMenuObject();
  resizeTransitionControll();
  richMenu();
  toggleHeader();
  toggleBtnFixed();
  togglePagetop();
  get_mrc_d_byte();
  tableHint();
  searchBoxSubmit();
  linkSelect();

  accordionCommon();
  accordionGMenu();
  accordionJobInt();
  accordionProdCdMap();
  accordionSelectFormRow();
  tabCommon();

  unitChange();
  specIframeResponsive();
});