import $ from "jQuery";

// -------------------------------------------------------------------
// htmlタグに以下のブラウザ判別用クラスを追加します
// -------------------------------------------------------------------
export function addBrowserClass() {
  var __add = function __add(name, ver) {
    $(".wrap").addClass(
      name +
      (ver != "" ? " " + name + (ver * 1).toString().replace(".", "_") : "")
    );
  };

  var userAgent = window.navigator.userAgent.toLowerCase();
  var appVersion = window.navigator.appVersion.toLowerCase();
  var get;

  if ((get = userAgent.match(/msie (\d+(\.\d+)?)/i))) {
    __add("ie", get[1]);
  } else if ((get = userAgent.match(/Trident.+rv\:(\d+(\.\d+)?)/i))) {
    __add("ie", get[1]);
  } else if ((get = userAgent.match(/chrome\/(\d+(\.\d+)?)/i))) {
    __add("chrome", get[1]);
  } else if ((get = userAgent.match(/firefox\/(\d+(\.\d+)?)/i))) {
    __add("firefox", get[1]);
  } else if ((get = userAgent.match(/opera\/(\d+(\.\d+)?)/i))) {
    __add("opera", get[1]);
  } else if ((get = userAgent.match(/safari\/(\d+(\.\d+)?)/i))) {
    __add("safari", get[1]);
  } // ついでにモバイルOS情報を付加する→ios(iphone, ipad, ipod), android

  if ((get = userAgent.match(/iPhone OS (\d+(\.\d+)?)/i))) {
    __add("ios", get[1]);
  }

  if ((get = userAgent.match(/iPhone;/i))) {
    __add("iphone", "");
  } else if ((get = userAgent.match(/iPod;/i))) {
    __add("ipod", "");
  } else if ((get = userAgent.match(/iPad;/i))) {
    __add("ipad", "");
  } else if ((get = userAgent.match(/Android (\d+(\.\d+)?)/i))) {
    __add("android", get[1]);
  }
} //リサイズ時のアニメーションを制御するプログラム

export function resizeTransitionControll() {
  var rsTimer = false,
    $_body = $("body");
  $(window).on("resize", function () {
    $_body.addClass("transition-stop");

    if (rsTimer !== false) {
      clearTimeout(rsTimer);
    }

    rsTimer = setTimeout(function () {
      $_body.removeClass("transition-stop");
    }, 100);
  });
} // スマホメニュー

export function SpMenuObject() {
  this.initialize();
}
SpMenuObject.prototype = {
  initialize: function initialize() {
    this.$_menuBtn = $(".js-open-menu-sp");
    this.$_menu = $(".js-g-menu");
    this.state = 0;
    this.current = "";
    this.handleEvents();
  },
  handleEvents: function handleEvents() {
    var self = this;
    self.$_menuBtn.on("click", function () {
      var target = $(this).data("target");
      if (self.state === 1 && self.current === target) {
        self.close();
      } else {
        self.$_menuBtn.removeClass("active");
        $(this).addClass("active");
        self.open(target);
      }
    });
    $(".js-g-menu-close").on("click", function () {
      self.close();
    });
    $(window).on("keydown", function (e) {
      if (e.keyCode == 27) {
        if (self.state === 1) {
          self.close();
        }
      }
    });
    //PCで表示されないように
    $(window).on("resize", function (e) {
      if ($(window).width() > 1024 && self.state === 1) {
        self.close();
      }
    });
  },
  open: function open(target) {
    $(".header").addClass("open");
    this.$_menu.addClass("open");
    $(".g-menu-sp-group").css("display", "none");
    $(".g-menu-sp-group--" + target).css("display", "block");
    this.state = 1;
    this.current = target;
  },
  close: function close() {
    $(".header").removeClass("open");
    this.$_menu.removeClass("open");
    this.$_menuBtn.removeClass("active");
    this.state = 0;
    this.current = "";
  },
};

export function toggleHeader() {
  var s = 0,
    preScroll = 0,
    loadFlag = false;

  setTimeout(function () {
    loadFlag = true;
  }, 1000);
  $(window).on("load scroll", function () {
    preScroll = s;
    s = $(this).scrollTop();

    if (s > 200) {
      $(".header").addClass("scroll");
    } else {
      $(".header").removeClass("scroll");
    }
    if (s < preScroll && loadFlag) {
      $(".header").removeClass("hidden");
    } else {
      $(".header").addClass("hidden");
    }
  });
}

export function toggleBtnFixed() {
  var s = 0;
  $(window).on("scroll", function () {
    s = $(this).scrollTop();

    if (s > 400) {
      $(".js-btn-fixed").addClass("active");
    } else {
      $(".js-btn-fixed").removeClass("active");
    }
  });
  $(".js-btn-fixed .btn-fixed-wrap__close").on("click", function () {
    $(".js-btn-fixed").addClass("hidden");
  });
}

export function togglePagetop() {
  $(window).on("scroll", function () {
    const s = $(this).scrollTop();
    if (400 > s) {
      $("#js-pagetop").removeClass("active");
    } else {
      $("#js-pagetop").addClass("active");
    }
  });
}

// mrc pdfの容量取得
export function get_mrc_d_byte() {
  var $_mrc_d_btn = $("a[data-mrc-download]");
  if ($_mrc_d_btn.length === 0) return;

  var $_mrc_d_btn_arr = [];
  $_mrc_d_btn.each(function () {
    $_mrc_d_btn_arr.push({
      el: this,
      no: $(this).data("mrc-download"),
    });
  });

  $.ajax({
    url: "https://script.google.com/macros/s/AKfycby3_9kLheq7nArINDFWFHN8iSBviwQ70rR1Nddccano90-2AbpQ/exec?cat=カタログダウンロードPDF-MRC",
    type: "GET",
    dataType: "json",
  })
    .done(function (data) {
      // success
      //取得jsonデータ
      var data_stringify = JSON.stringify(data); //JSON.stringify() メソッドは、ある JavaScript のオブジェクトや値を JSON 文字列に変換します。
      var data_json = JSON.parse(data_stringify);

      $_mrc_d_btn_arr.forEach((element) => {
        var data = data_json.filter(function (item) {
          //data_jsonから一致するidの情報を取得、var dataに代入
          return item.ダウンロードNO == element.no;
        });
        if (data.length === 0) return;
        $(".btn-dl__name", element.el).text(data[0].タイトル);
        $(".btn-dl__byte", element.el).text("[" + data[0].カタログ容量 + "]");
      });
    })
    .fail(function (data) {
      //.fail:Ajax通信に失敗した際に呼び出される。
      console.log("error");
    });
}

// テーブルスクロールUI
export function tableHint() {
  var tableHintHtml = `<div class="table-wrap-hint">
          <div class="table-wrap-hint__icon">
            <i class="bi bi-hand-index table-wrap-hint__hand"></i>
          </div>
          <p class="table-wrap-hint__txt">スクロールできます</p>
        </div>`;
  var $_table_wrap = $(".table-wrap, .prod-rent-table-stk-wrap");

  if (!$_table_wrap.length) return;

  $_table_wrap.each(function () {
    $(this).on("scroll", function () {
      $(".table-wrap-hint", this).addClass("disable");
    });
  });
  $(window).on("scroll", function () {
    var s = $(this).scrollTop() + $(window).height() * 0.75;
    $_table_wrap.each(function () {
      if ($(this).data("done")) return;

      var target_top = $(this).offset().top;
      if (s > target_top) {
        $(this).append(tableHintHtml);
        $(this).data("done", true);
      }
    });
  });
}

// 検索のSubmit
export function searchBoxSubmit() {
  $(".search-box .search-box__btn").on("click", function () {
    $(this).parent().submit();
  });
}

// セレクトボックスでページ遷移
export function linkSelect() {
  $(".js-link-select").on("change", function () {
    var link = $(this).val();
    location.href = $(this).val();
  });
}

// .accordion
export function accordionCommon() {
  if ($(window).width() > 767) {
    $(".accordion.is-open-pc").each(function () {
      $(this).addClass("open");
      $(".accordion__b", this).css("display", "block");
    });
  }
  $(".accordion.is-open").each(function () {
    $(this).addClass("open");
    $(".accordion__b", this).css("display", "block");
  });
  $(".accordion__h").on("click", function () {
    let $_accordion = $(this).parent();
    if ($_accordion.hasClass("open")) {
      $_accordion.removeClass("open");
      $(".accordion__b", $_accordion).slideUp();
      return false;
    }
    $_accordion.addClass("open");
    $(".accordion__b", $_accordion).slideDown();
  });
  // ページ遷移時にアコーディオンを開く
  // URLのハッシュ部分（id）を取得
  const urlHash = location.hash;
  if ($(urlHash).length) {
    $(urlHash).addClass("open");
    $(".accordion__b", $(urlHash)).slideDown();
  }
}
// SP g-menuのアコーディオン
export function accordionGMenu() {
  function init() {
    isInit = true;
    $(".g-menu-acco-h").on("click.acco", function (e) {
      e.preventDefault();

      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $("+ ul", this).slideUp();
      } else {
        $(this).addClass("active");
        $("+ ul", this).slideDown();
      }
    });
  }
  let isInit = false;
  if (767 >= $(window).width()) {
    if (!isInit) {
      init();
    }
  }
  $(window).on("resize", function () {
    if (767 >= $(window).width()) {
      if (!isInit) {
        init();
      }
    } else {
      $(".g-menu-acco-h").off("click.acco");
    }
  });
}

// 採用-インタビューのアコーディオン
export function accordionJobInt() {
  $(".job-staff-int__acco-h").on("click", function () {
    let $_accordion = $(".job-staff-int--" + $(this).data("target"));
    if ($_accordion.hasClass("open")) {
      $_accordion.removeClass("open");
      $(this).removeClass("open");
      $(this).text("詳細を見る");
      $(".job-staff-int__body", $_accordion).slideUp();
      return false;
    }
    $_accordion.addClass("open");
    $(this).addClass("open");
    $(this).text("詳細を閉じる");
    $(".job-staff-int__body", $_accordion).slideDown();
  });
}

// prod-cd-map のアコーディオン処理
export function accordionProdCdMap() {
  $(".prod-cd-map a, .prod-cd-map-sp a").on("click", function () {
    var $_target = $($(this).attr("href"));

    if ($(".accordion", $_target).hasClass("open")) return;
    $($_target).addClass("open");
    $(".accordion__b", $_target).slideDown();
  });
}

// select-form__rowのアコーディオン
export function accordionSelectFormRow() {
  if (767 > $(window).width()) {
    $(".select-form__row-h").on("click", function () {
      if ($(this).hasClass("open")) {
        $(this).removeClass("open");
        $(this).next().slideUp();
      } else {
        $(this).addClass("open");
        $(this).next().slideDown();
      }
    });
  }
}

// 共通のタブプログラム
export function tabCommon() {
  const getParameterByName = (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}
  
  const getTargetIdArr = (target) => {
    const targetIdArr = [];
    target.querySelectorAll('a').forEach((link) => {
      const href = $(link).attr('data-target');
      targetIdArr.push(href)
    })
    return targetIdArr;
  }

  const tabReset = (targetTabLink) => {
    const targetIdArr = getTargetIdArr(targetTabLink)
    targetIdArr.forEach((targetId) => {
      document.getElementById(targetId).style.display = 'none';
    })
    tabLinks.forEach((targetTabLinks) => {
      $('a', targetTabLinks).removeClass('current');
    })
  }

  const set = (targetTabLink, id) => {
    document.getElementById(id).style.display = 'block';
    $(`a[data-target="${id}"]`, targetTabLink).addClass('current');
  }

  const main = (targetTabLink) => {
    const initialTabid = getParameterByName('tabid');
    const targetIdArr = getTargetIdArr(targetTabLink);
    
    tabReset(targetTabLink)

    let activeId = activeId = targetIdArr[0];
    targetIdArr.forEach((targetId) => {
      if(initialTabid === targetId) {
        activeId = targetId;
      }
    })
    set(targetTabLink, activeId);

    $('a', targetTabLink).on('click', function(e) {
      e.preventDefault();
      const targetId = $(this).attr('data-target');
      tabReset(targetTabLink);
      set(targetTabLink, targetId);
    })
  }

  const tabLinks = document.querySelectorAll('.js-tab-links');
  tabLinks.forEach((targetTabLink) => {
    main(targetTabLink)
  })

}

// 英語 単位の切り替え処理
export function unitChange() {
  function change(state) {
    $(".uc-wrap").css("display", "none");
    $(".uc-wrap." + state).css("display", "block");
    $(".uc-btn").removeClass("current");
    $('.uc-btn[data-target="' + state + '"]').addClass("current");
  }
  function changeGlobal() {
    $(".uc-wrap").css("display", "none");
    $('.uc-wrap.uc-w-1').css("display", "block");
    $(".uc-btn").removeClass("current");
    $('.uc-btn[data-target="uc-w-1"]').addClass("current");

    $(".uc-wrap.uc-wrap--has-global").css("display", "none");
    $('.uc-wrap.uc-wrap--has-global.uc-w-3').css("display", "block");
    $(".uc-btn-wrap--has-global .uc-btn").removeClass("current");
    $('.uc-btn-wrap--has-global .uc-btn[data-target="uc-w-3"]').addClass("current");
  }
  var state = "uc-w-1";
  change(state);
  $(".uc-btn").on("click", function () {
    if( $(this).hasClass('uc-btn--global') ) return;
    var state = $(this).data("target");
    change(state);
  });
  $('.uc-btn-wrap--has-global .uc-btn[data-target="uc-w-3"]').on('click', function() {
    changeGlobal();
  });
}

// 仕様表のレスポンシブ対応
export function specIframeResponsive() {
  $(window).on("load resize", function () {
    var pc_w = $(".prod-spec-iframe").width() / 1200;
    $(".prod-spec-iframe iframe").css("transform", "scale(" + pc_w + ")");
  });
}
